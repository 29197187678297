import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import ReactExport from "react-data-export";
import { BotonExcel } from "../Plantillas/Botones";
import { decimalAdjust } from "../../Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ReporteVentasVales = () => {
    const [params, setParams] = useState({
        inicio: moment(new Date()).subtract({ days: 7 }).format('YYYY-MM-DD'),
        fin: moment(new Date()).format('YYYY-MM-DD')
    })
    const [sucursales,setSucursales] = useState([])
    const [exportData, setExportData] = useState([]);

    useEffect(() => {
        setExportData(getDataExportExcel())
    }, [sucursales])

    const getDataExportExcel = () => {
        return [
            {
                columns: [
                    {
                        title: `SUCURSAL`,
                        width: { wpx: 150 }
                    },
                    {
                        title: `VALES`,
                        width: { wpx: 150 }
                    },
                    {
                        title: `MONTO VALES (S/ )`,
                        width: { wpx: 200 }
                    },
                    {
                        title: `VENTAS`,
                        width: { wpx: 200 }
                    },
                    {
                        title: `MONTO VENTAS (S/ )`,
                        width: { wpx: 200 }
                    },
                    {
                        title: `TOTAL`,
                        width: { wpx: 200 }
                    },
                    {
                        title: `TOTAL (S/ )`,
                        width: { wpx: 200 }
                    },
                ],
                data: sucursales.map(row => {
                    return [
                        {
                            value: row.Sucursal,
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: row.Vales,
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: decimalAdjust('floor', row.MontoVales, -3),
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: row.Ventas,
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: decimalAdjust('floor', row.MontoVentas, -3),
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: row.Vales + row.Ventas,
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                        {
                            value: decimalAdjust('floor', row.MontoVentas + row.MontoVales, -2),
                            style: {
                                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                            }
                        },
                    ];
                })
            }
        ];
    }

    const onFilter = async () => {
        try {
            const searchParams = new URLSearchParams(params);
            const req = await fetch(`/api/sucursales/reporte-ventas-vales?${searchParams.toString()}`);

            if (!req.ok) {
                throw new Error(await req.text());
            }

            setSucursales(await req.json());
        } catch (e) {
            console.error(e)
            setSucursales([])
        } 
    }

    return <section className="ventas-fpay-section-vh">
        <div className=" pt-3 pb-3">
            <div className="justify-content-center contenedor-fecha-fpay caja-fondo-blanco-m-2">
                <span className="align-self-center letra-fecha-fpay">
                    Desde:
                </span>

                <input  type="date"
                        name="inicio"
                        className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                        onChange={e => setParams({...params, [e.target.name]: e.target.value})}
                        value={params.inicio} />

                <span className="align-self-center letra-fecha-fpay">
                    Hasta:
                </span>

                <input  type="date"
                        name="fin"
                        className="input__linea mr-1 pl-2 mt-3 mb-3 linea-fecha-fpay"
                        onChange={e => setParams({...params, [e.target.name]: e.target.value})}
                    value={params.fin} />
            </div>

            <div className="mt-3 container">
                <div className="col-sm-auto seccion-btn-verde-mfp no-pad">
                    <ExcelFile
                        element={
                            <BotonExcel
                                className="mr-2"
                                type="button"
                                title="Guardar Excel"
                                />
                        }
                        filename={`ReporteVentasVales`}
                    >
                        <ExcelSheet
                            dataSet={exportData}
                            name="EmpresasFile"
                        >
                    </ExcelSheet>
                </ExcelFile>
                    <button onClick={onFilter}
                        className="btn btn-outline-primary btn-verde-mfp">
                        Filtrar
                    </button>
                </div>
            </div>

            <div className="container mt-3">
                <ReactTable
                    data={sucursales}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                    }
                    columns={[
                    {
                        columns: [
                        {
                            Header: "Sucursal",
                            id: "Sucursal",
                            headerClassName: "",
                            width: 200,
                            filterAll: true,
                            accessor: (d) => d.Sucursal,
                        },
                        {
                            Header: "Vales",
                            id: "Vales",
                            headerClassName: "",
                            width: 150,
                            filterAll: true,
                            accessor: (d) => d.Vales,
                        },
                        {
                            Header: "Monto Vales (S/ )",
                            id: "MontoVales",
                            accessor: (d) => decimalAdjust('floor', d.MontoVales, -3),
                            headerClassName: "",
                            width: 150,
                            filterAll: true,
                        },
                        {
                            Header: "Ventas",
                            id: "Ventas",
                            headerClassName: "",
                            width: 150,
                            filterAll: true,
                            accessor: (d) => d.Ventas,
                        },
                        {
                            Header: "Monto Ventas (S/ )",
                            id: "MontoVentas",
                            accessor: (d) => decimalAdjust('floor', d.MontoVentas, -3),
                            headerClassName: "",
                            width: 150,
                            filterAll: true,
                        },
                        {
                            Header: "Total",
                            id: "Total",
                            accessor: (d) => d.Ventas + d.Vales,
                            headerClassName: "",
                            width: 150,
                            filterAll: true,
                        },
                        {
                            Header: "Total (S/ )",
                            id: "Total",
                            accessor: (d) => decimalAdjust('floor', d.MontoVentas + d.MontoVales, -3),
                            headerClassName: "",
                            width: 175,
                            filterAll: true,
                        },
                        ],
                    },
                    ]}
                    defaultPageSize={10}
                    //pageSize={10}
                    className="-striped -highlight"
                    // Text de paginación
                    previousText="Anterior"
                    nextText="Siguiente"
                    loadingText="Cargando..."
                    noDataText="No se encontraron registros"
                    pageText="Página"
                    ofText="de"
                    rowsText="filas"
                    pageSizeOptions={[10, 20]}
                />
            </div>
        </div>

    </section>
}

export default ReporteVentasVales;
